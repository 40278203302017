import React from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';
import { getDropdownItems, isAccountItemsVisibe } from './getters';
import { getFlexDirection, mapAlign } from '~/utils/styles';
import type { loader } from '~/routes/_index';
import type {
  AccountButtonGeneral,
  AccountButtonLocalizations,
  AccountButtonStyles,
  DropdownItem,
  StaticLinks,
} from './types';
import { ImageHover } from '~/components/image-hover';
import { Underline } from '~/components/underline';
import {
  getAccountDropdownButtonClassNames,
  getAccountDropdownMenuClassNames,
} from './get-class-names';

export function AccountDropdown({
  classNameId,
  styles,
  general,
  isLoggedIn,
  localizations,
}: {
  classNameId: string;
  styles: AccountButtonStyles;
  general: AccountButtonGeneral;
  isLoggedIn: boolean | null;
  localizations: AccountButtonLocalizations;
}) {
  const AccountDropdownButtonComponent = (
    <AccountDropdownButton
      label={localizations.dropdown_button_label}
      isLoggedIn={isLoggedIn}
      styles={styles}
    />
  );

  const AccountDropdownMenuComponent = (
    <AccountDropdownMenu
      styles={styles}
      isLoggedIn={isLoggedIn}
      general={general}
    />
  );

  return (
    <AccountDropdownMenuToggle
      AccountDropdownButtonComponent={AccountDropdownButtonComponent}
      AccountDropdownMenuComponent={AccountDropdownMenuComponent}
      classNameId={classNameId}
      position={
        styles.account_dropdown_menu_position_relative_to_button as
          | 'left'
          | 'center'
          | 'right'
      }
    />
  );
}

function AccountDropdownButton({
  label,
  isLoggedIn,
  styles,
}: {
  label: string | undefined;
  isLoggedIn: boolean | null;
  styles: AccountButtonStyles;
}) {
  const isVisible: boolean = isAccountItemsVisibe({
    styles,
    isLoggedIn,
    manifestKey: 'accounts_dropdown_visibility',
  });

  if (!isVisible) return <></>;

  const accountDropdownButtonClassNames: {
    account_dropdown_button: string;
    account_dropdown_button_title: string;
    account_dropdown_button_chevron_asset: string;
    account_dropdown_button_asset: string;
    account_dropdown_button_underline: string;
  } = getAccountDropdownButtonClassNames(styles);

  return (
    <div className="group" data-testid={`account-button2`}>
      <div className={accountDropdownButtonClassNames.account_dropdown_button}>
        <div
          className={`flex items-center justify-center ${getFlexDirection(
            styles.account_dropdown_button_chevron_asset_alignment as
              | 'left'
              | 'right'
          )}`}
        >
          {styles.account_dropdown_button_title_switch && (
            <div
              className={
                accountDropdownButtonClassNames.account_dropdown_button_title
              }
            >
              {label}
            </div>
          )}
          <ImageHover
            defaultImageSrc={
              styles.account_dropdown_button_chevron_asset_default
            }
            defaultImageSwitch={
              styles.account_dropdown_button_chevron_asset_switch
            }
            hoverImageSrc={styles.account_dropdown_button_chevron_asset_hover}
            hoverImageSwitch={
              styles.account_dropdown_button_chevron_asset_hover_switch
            }
            classNames={
              accountDropdownButtonClassNames.account_dropdown_button_chevron_asset
            }
          />
        </div>
        <ImageHover
          defaultImageSrc={styles.account_dropdown_button_asset_default}
          defaultImageSwitch={styles.account_dropdown_button_asset_switch}
          hoverImageSrc={styles.account_dropdown_button_asset_hover}
          hoverImageSwitch={styles.account_dropdown_button_asset_hover_switch}
          classNames={
            accountDropdownButtonClassNames.account_dropdown_button_asset
          }
        />
      </div>
      <Underline
        classNames={
          accountDropdownButtonClassNames.account_dropdown_button_underline
        }
      />
    </div>
  );
}

function AccountDropdownMenu({
  styles,
  general,
  isLoggedIn,
}: {
  styles: AccountButtonStyles;
  general: AccountButtonGeneral;
  isLoggedIn: boolean | null;
}) {
  const { staticLinks } = useLoaderData<typeof loader>();

  const dropdownItems: DropdownItem[] = getDropdownItems({
    staticLinks,
    styles,
    general,
    isLoggedIn,
  } as {
    staticLinks: StaticLinks;
    styles: AccountButtonStyles;
    general: AccountButtonGeneral;
    isLoggedIn: boolean | null;
  });

  const accountDropdownMenuClassNames: {
    account_dropdown_menu: string;
    account_dropdown_menu_auth_buttons_styling_divider: string;
    account_dropdown_menu_item: Function;
    account_dropdown_menu_item_title: Function;
    account_dropdown_menu_item_asset: Function;
  } = getAccountDropdownMenuClassNames({
    styles,
  });

  return (
    <div className={accountDropdownMenuClassNames.account_dropdown_menu}>
      {React.Children.toArray(
        dropdownItems.map((dropdownItem: DropdownItem, i: number) => {
          i = i + 1;

          const {
            label,
            href,
            defaultImageSrc,
            defaultImageSwitch,
            hoverImageSrc,
            hoverImageSwitch,
            isAuthButtonsDivider,
            authButtonType,
          }: DropdownItem = dropdownItem;

          const isVisible: boolean = isAccountItemsVisibe({
            styles,
            isLoggedIn,
            manifestKey: `extra_link_${i}_visibility`,
          });

          if (!isVisible && !authButtonType) return <></>;

          return (
            <div>
              {isAuthButtonsDivider && (
                <div
                  className={
                    accountDropdownMenuClassNames.account_dropdown_menu_auth_buttons_styling_divider
                  }
                />
              )}
              <Link
                to={href}
                key={`account-dropdown-menu-item-${i}`}
                data-testid={`account-dropdown-menu-item-${i}`}
              >
                <div className="group">
                  <div
                    className={accountDropdownMenuClassNames.account_dropdown_menu_item(
                      authButtonType
                    )}
                  >
                    <div
                      className={accountDropdownMenuClassNames.account_dropdown_menu_item_title(
                        authButtonType
                      )}
                    >
                      {label}
                    </div>
                    <ImageHover
                      defaultImageSrc={defaultImageSrc}
                      defaultImageSwitch={defaultImageSwitch}
                      hoverImageSrc={hoverImageSrc}
                      hoverImageSwitch={hoverImageSwitch}
                      classNames={accountDropdownMenuClassNames.account_dropdown_menu_item_asset(
                        authButtonType
                      )}
                    />
                  </div>
                </div>
              </Link>
            </div>
          );
        })
      )}
    </div>
  );
}

function AccountDropdownMenuToggle({
  AccountDropdownButtonComponent,
  AccountDropdownMenuComponent,
  classNameId,
  position,
}: {
  AccountDropdownButtonComponent: React.ReactNode;
  AccountDropdownMenuComponent: React.ReactNode;
  classNameId: string;
  position: 'left' | 'center' | 'right';
}) {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger>
        {AccountDropdownButtonComponent}
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          className={`${classNameId} z-50`}
          align={mapAlign(position)}
        >
          {AccountDropdownMenuComponent}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}

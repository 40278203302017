import {
  createFontFields,
  createMarginFields,
  createPaddingFields,
  createShadowFields,
  createStyleField,
  createUnderlineFields,
} from '../../../../utils/create-style-field.ts';

export const manifestReservedWords = [
  'button',
  'title',
  'menu',
  'item',
  'asset',
  'underline',
  'chevron',
  'divider',
  'auth',
  'shadow',
];

const manifest = {
  plugin_id: 3907,
  identifier: 'responsive_nav_bar_account_button_2',
  manifest_version: '1.1.0',
  dependency_name: '',
  name: 'Responsive Nav Bar Account Button 2',
  description: 'Responsive Nav Bar Account Button 2',
  type: 'nav_item',
  localizations: {
    fields: [
      {
        type: 'text_input',
        label: 'Sign in button label',
        key: 'sign_in_button_label',
        initial_value: 'Sign In',
      },
      {
        type: 'text_input',
        label: 'Sign out button label',
        key: 'sign_out_button_label',
        initial_value: 'Sign Out',
      },
      {
        type: 'text_input',
        label: 'Sign up button label',
        key: 'sign_up_button_label',
        initial_value: 'Sign Up',
      },
      {
        type: 'text_input',
        label: 'Dropdown button label',
        key: 'dropdown_button_label',
        initial_value: 'My Account',
      },
    ],
  },
  general: {
    fields: [
      {
        key: 'sign_up_link_type',
        label: 'Sign Up Link Type',
        type: 'select',
        options: [
          {
            value: 'screen_target',
            text: 'Screen',
          },
          {
            value: 'external_link',
            text: 'External Link',
          },
        ],
        initial_value: 'screen_target',
      },
      {
        key: 'sign_up_link_target',
        label: 'Sign Up Link Target',
        type: 'screen_selector',
        conditional_fields: [
          {
            key: 'general/sign_up_link_type',
            condition_value: 'screen_target',
          },
        ],
      },
      {
        key: 'sign_up_link_path',
        label: 'Sign Up Link Path',
        type: 'text_input',
        conditional_fields: [
          {
            key: 'general/sign_up_link_type',
            condition_value: 'external_link',
          },
        ],
      },
    ],
  },
  styles: {
    fields: [
      {
        label: 'Authentication Buttons',
        group: true,
        tooltip:
          'You can expose the sign in, sign up, and sign out buttons in the top menu. or expose them in the account dropdown button.',
        fields: [
          {
            key: 'auth_buttons_switch',
            label: 'Enable Buttons',
            type: 'switch',
            initial_value: true,
          },
          createStyleField({
            key: 'auth_buttons_horizontal',
            label: 'Horizontal gutter',
            type: 'gutter',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/auth_buttons_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'auth_button_size',
            label: 'Item size',
            type: 'select',
            options: [
              { text: 'Dynamic', value: 'dynamic' },
              { text: 'Fixed', value: 'fixed' },
            ],
            initial_value: 'dynamic',
            conditional_fields: [
              {
                key: 'styles/auth_buttons_switch',
                condition_value: true,
              },
            ],
          },
          createStyleField({
            key: 'auth_button',
            label: 'Item width',
            type: 'width',
            initial_value: 120,
            conditional_fields: [
              {
                key: 'styles/auth_button_size',
                condition_value: 'fixed',
              },
            ],
          }),
          ...createUnderlineFields({
            key: 'auth_button',
            label: 'Auth Button',
            initial_values: {
              underline_background_color: 'transparent',
              underline_background_color_hover: 'transparent',
              underline_thickness: 0,
              underline_corner_radius: 0,
            },
            conditional_fields: [
              {
                key: 'styles/auth_buttons_switch',
                condition_value: true,
              },
            ],
          }),
          ...createMarginFields({
            key: 'auth_button',
            label: 'Auth Button',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/auth_buttons_switch',
                condition_value: true,
              },
            ],
          }),
          ...createPaddingFields({
            key: 'auth_button',
            label: 'Auth Button',
            initial_values: {
              padding_top: 8,
              padding_right: 16,
              padding_bottom: 8,
              padding_left: 16,
            },
            conditional_fields: [
              {
                key: 'styles/auth_buttons_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'auth_button',
            label: 'Item border thickness',
            type: 'border_thickness',
            initial_value: 1.5,
            conditional_fields: [
              {
                key: 'styles/auth_buttons_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'auth_button',
            label: 'Item corner radius',
            type: 'corner_radius',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/auth_buttons_switch',
                condition_value: true,
              },
            ],
          }),
        ],
      },
      {
        label: 'Sign In',
        group: true,
        fields: [
          {
            key: 'sign_in_button_switch',
            label: 'Enable Button',
            type: 'switch',
            initial_value: true,
          },
          createStyleField({
            key: 'sign_in_default_item',
            label: 'Default item background color',
            type: 'background_color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/sign_in_button_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'sign_in_hover_item',
            label: 'Hover item background color',
            type: 'background_color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/sign_in_button_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'sign_in',
            label: 'Default item border color',
            type: 'border_color',
            initial_value: 'rgba(239, 239, 239, 0.25)',
            conditional_fields: [
              {
                key: 'styles/sign_in_button_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'sign_in_hover',
            label: 'Hover item border color',
            type: 'border_color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/sign_in_button_switch',
                condition_value: true,
              },
            ],
          }),
        ],
      },
      {
        label: 'Sign Up',
        group: true,
        fields: [
          {
            key: 'sign_up_button_switch',
            label: 'Enable Button',
            type: 'switch',
            initial_value: true,
          },
          createStyleField({
            key: 'sign_up_default_item',
            label: 'Default item background color',
            type: 'background_color',
            initial_value: 'rgba(254, 20, 72, 1)',
            conditional_fields: [
              {
                key: 'styles/sign_up_button_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'sign_up_hover_item',
            label: 'Hover item background color',
            type: 'background_color',
            initial_value: 'rgba(190, 14, 53, 1)',
            conditional_fields: [
              {
                key: 'styles/sign_up_button_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'sign_up',
            label: 'Default item border color',
            type: 'border_color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/sign_up_button_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'sign_up_hover',
            label: 'Hover item border color',
            type: 'border_color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/sign_up_button_switch',
                condition_value: true,
              },
            ],
          }),
        ],
      },
      {
        label: 'Sign Out',
        group: true,
        fields: [
          {
            key: 'sign_out_button_switch',
            label: 'Enable Button',
            type: 'switch',
            initial_value: true,
          },
          createStyleField({
            key: 'sign_out_default_item',
            label: 'Default item background color',
            type: 'background_color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/sign_out_button_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'sign_out_hover_item',
            label: 'Hover item background color',
            type: 'background_color',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/sign_out_button_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'sign_out',
            label: 'Default item border color',
            type: 'border_color',
            initial_value: 'rgba(239, 239, 239, 0.25)',
            conditional_fields: [
              {
                key: 'styles/sign_out_button_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'sign_out_hover',
            label: 'Hover item border color',
            type: 'border_color',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/sign_out_button_switch',
                condition_value: true,
              },
            ],
          }),
        ],
      },
      {
        label: 'Authentication Buttons Assets',
        group: true,
        fields: [
          {
            key: 'auth_buttons_asset_switch',
            label: 'Enable Asset',
            type: 'switch',
            initial_value: false,
          },
          createStyleField({
            key: 'auth_buttons_asset',
            label: 'Width',
            type: 'width',
            initial_value: 24,
          }),
          createStyleField({
            key: 'auth_buttons_asset',
            label: 'Height',
            type: 'height',
            initial_value: 24,
          }),
          ...createMarginFields({
            key: 'auth_buttons_asset',
            label: 'Auth Buttons Asset',
            initial_values: {
              margin_top: 0,
              margin_right: 8,
              margin_bottom: 0,
              margin_left: 0,
            },
          }),
          {
            key: 'auth_buttons_asset_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
          },
        ],
      },
      {
        label: 'Sign In Assets',
        group: true,
        fields: [
          {
            key: 'sign_in_default_asset',
            label: 'Default asset',
            type: 'uploader',
          },
          {
            key: 'sign_in_hover_asset_switch',
            label: 'Enable hover asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'sign_in_hover_asset',
            label: 'Hover asset',
            type: 'uploader',
          },
        ],
      },
      {
        label: 'Sign Up Assets',
        group: true,
        fields: [
          {
            key: 'sign_up_default_asset',
            label: 'Default asset',
            type: 'uploader',
          },
          {
            key: 'sign_up_hover_asset_switch',
            label: 'Enable hover asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'sign_up_hover_asset',
            label: 'Hover asset',
            type: 'uploader',
          },
        ],
      },
      {
        label: 'Sign Out Assets',
        group: true,
        fields: [
          {
            key: 'sign_out_default_asset',
            label: 'Default asset',
            type: 'uploader',
          },
          {
            key: 'sign_out_hover_asset_switch',
            label: 'Enable hover asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'sign_out_hover_asset',
            label: 'Hover asset',
            type: 'uploader',
          },
        ],
      },
      {
        label: 'Title',
        group: true,
        fields: [
          {
            key: 'auth_buttons_title_switch',
            label: 'Enable',
            type: 'switch',
            initial_value: true,
          },
          ...createFontFields({
            key: 'auth_buttons_title',
            label: 'Title',
            initial_values: {
              font_family: 'Ubuntu-Medium',
              font_size: 14,
              line_height: 24,
              letter_spacing: 0,
            },
            conditional_fields: [
              {
                key: 'styles/auth_buttons_title_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'auth_buttons_title_text_transform',
            label: 'Text transform',
            type: 'select',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
          },
          {
            key: 'auth_buttons_title_text_decoration',
            label: 'Default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
          },
          {
            key: 'auth_buttons_title_text_decoration_hover',
            label: 'Hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
          },
        ],
      },
      {
        label: 'Sign In Title',
        group: true,
        fields: [
          {
            key: 'sign_in_default_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
          },
          {
            key: 'sign_in_hover_font_color',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
          },
        ],
      },
      {
        label: 'Sign Up Title',
        group: true,
        fields: [
          {
            key: 'sign_up_default_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
          },
          {
            key: 'sign_up_hover_font_color',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
          },
        ],
      },
      {
        label: 'Sign Out Title',
        group: true,
        fields: [
          {
            key: 'sign_out_default_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
          },
          {
            key: 'sign_out_hover_font_color',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
          },
        ],
      },
      {
        label: 'Account Dropdown Button',
        group: true,
        fields: [
          {
            key: 'accounts_dropdown_visibility',
            label: 'Accounts Dropdown Visibility',
            type: 'select',
            options: [
              { text: 'Only When Signed In', value: 'when_signed_in' },
              { text: 'Only When Signed Out', value: 'when_signed_out' },
              { text: 'Always', value: 'always' },
              { text: 'Never', value: 'never' },
            ],
            initial_value: 'when_signed_in',
          },
          {
            key: 'account_dropdown_button_size',
            label: 'Button size',
            type: 'select',
            options: [
              { text: 'Dynamic', value: 'dynamic' },
              { text: 'Fixed', value: 'fixed' },
            ],
            initial_value: 'dynamic',
          },
          createStyleField({
            key: 'account_dropdown_button',
            label: 'Button width',
            type: 'width',
            initial_value: 120,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_button_size',
                condition_value: 'fixed',
              },
            ],
          }),
          ...createUnderlineFields({
            key: 'account_dropdown_button',
            label: 'Dropdown Button',
            initial_values: {
              underline_background_color: 'transparent',
              underline_background_color_hover: 'transparent',
              underline_thickness: 0,
              underline_corner_radius: 0,
            },
          }),
          ...createMarginFields({
            key: 'account_dropdown_button',
            label: 'Account Dropdown Button',
            initial_value: 0,
          }),
          ...createPaddingFields({
            key: 'account_dropdown_button',
            label: 'Account Dropdown Button',
            initial_value: 8,
          }),
          {
            key: 'account_dropdown_button_background_color',
            label: 'Default item background color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'account_dropdown_button_hover_background_color',
            label: 'Hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(30, 30, 30, 1)',
          },
          {
            key: 'account_dropdown_button_border_color',
            label: 'Default item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'account_dropdown_button_hover_border_color',
            label: 'Hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'account_dropdown_button_border_thickness',
            label: 'Item border thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'account_dropdown_button_corner_radius',
            label: 'Item corner radius',
            type: 'number_input',
            initial_value: 6,
          },
        ],
      },
      {
        label: 'Account Dropdown Button Asset',
        group: true,
        fields: [
          {
            key: 'account_dropdown_button_asset_switch',
            label: 'Enable',
            type: 'switch',
            initial_value: true,
          },
          createStyleField({
            key: 'account_dropdown_button_asset',
            label: 'Width',
            type: 'width',
            initial_value: 24,
          }),
          createStyleField({
            key: 'account_dropdown_button_asset',
            label: 'Height',
            type: 'height',
            initial_value: 24,
          }),
          {
            key: 'account_dropdown_button_asset_default',
            label: 'Default asset',
            type: 'uploader',
            initial_value: '/account-dropdown-button-asset.svg',
          },
          {
            key: 'account_dropdown_button_asset_hover_switch',
            label: 'Enable hover asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'account_dropdown_button_asset_hover',
            label: 'Hover asset',
            type: 'uploader',
          },
          {
            key: 'account_dropdown_button_asset_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
          },
          ...createMarginFields({
            key: 'account_dropdown_button_asset',
            label: 'Account Dropdown Button Asset',
            initial_value: 0,
          }),
        ],
      },
      {
        label: 'Account Dropdown Button Chevron Asset',
        group: true,
        fields: [
          {
            key: 'account_dropdown_button_chevron_asset_switch',
            label: 'Enable',
            type: 'switch',
            initial_value: false,
          },
          createStyleField({
            key: 'account_dropdown_button_chevron_asset',
            label: 'Width',
            type: 'width',
            initial_value: 16,
          }),
          createStyleField({
            key: 'account_dropdown_button_chevron_asset',
            label: 'Height',
            type: 'height',
            initial_value: 16,
          }),
          {
            key: 'account_dropdown_button_chevron_asset_height',
            label: 'Height',
            type: 'number_input',
            initial_value: 16,
          },
          {
            key: 'account_dropdown_button_chevron_asset_default',
            label: 'Default asset',
            type: 'uploader',
            initial_value: '/account-dropdown-button-chevron-asset.svg',
          },
          {
            key: 'account_dropdown_button_chevron_asset_hover_switch',
            label: 'Enable hover asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'account_dropdown_button_chevron_asset_hover',
            label: 'Hover asset',
            type: 'uploader',
          },
          {
            key: 'account_dropdown_button_chevron_asset_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'right',
          },
          ...createMarginFields({
            key: 'account_dropdown_button_chevron_asset',
            label: 'Chevron Asset',
            initial_value: 0,
          }),
        ],
      },
      {
        label: 'Account Dropdown Button Title',
        group: true,
        fields: [
          {
            key: 'account_dropdown_button_title_switch',
            label: 'Enable',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'account_dropdown_button_title_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(158, 158, 158, 1)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_button_title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_button_title_font_color_hover',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_button_title_switch',
                condition_value: true,
              },
            ],
          },
          ...createFontFields({
            key: 'account_dropdown_button_title',
            label: 'Title',
            initial_values: {
              font_family: 'Ubuntu-Medium',
              font_size: 14,
              line_height: 24,
              letter_spacing: 0,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_button_title_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'account_dropdown_button_title_text_transform',
            label: 'Text transform',
            type: 'select',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_button_title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_button_title_text_decoration',
            label: 'Default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_button_title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_button_title_text_decoration_hover',
            label: 'Hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_button_title_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu',
        group: true,
        fields: [
          {
            key: 'account_dropdown_menu_position_relative_to_button',
            label: 'Position relative to button',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'right',
          },
          createStyleField({
            key: 'account_dropdown_menu',
            label: 'Width',
            type: 'width',
            initial_value: 180,
          }),
          {
            key: 'account_dropdown_menu_background_color',
            label: 'Background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(30, 30, 30, 1)',
          },
          {
            key: 'account_dropdown_menu_corner_radius',
            label: 'Corner radius',
            type: 'number_input',
            initial_value: 8,
          },
          {
            key: 'account_dropdown_menu_border_color',
            label: 'Border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'account_dropdown_menu_border_thickness',
            label: 'Border thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'account_dropdown_menu_vertical_gutter',
            label: 'Vertical gutter',
            type: 'number_input',
            initial_value: 0,
          },
          ...createMarginFields({
            key: 'account_dropdown_menu',
            label: 'Account Dropdown Menu',
            initial_values: {
              margin_top: 8,
              margin_right: 0,
              margin_bottom: 0,
              margin_left: 0,
            },
          }),
          ...createPaddingFields({
            key: 'account_dropdown_menu',
            label: 'Account Dropdown Menu',
            initial_values: {
              padding_top: 6,
              padding_right: 0,
              padding_bottom: 6,
              padding_left: 0,
            },
          }),
          {
            key: 'account_dropdown_menu_shadow_switch',
            label: 'Enable shadow',
            type: 'switch',
            initial_value: false,
          },
          ...createShadowFields({
            key: 'account_dropdown_menu',
            label: 'Account Dropdown Menu',
            initial_values: {
              shadow_x_offset: 0,
              shadow_y_offset: 8,
              shadow_blur: 16,
              shadow_spread: -4,
              shadow_color: 'rgba(0, 0, 0, 0.25)',
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_shadow_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'account_dropdown_menu_background_blur',
            label: 'Background blur',
            type: 'number_input',
            initial_value: 0,
            label_tooltip:
              'Please note: In order to see the background blur effect, you need to set the background opacity to a value lower than 100.',
          },
        ],
      },
      {
        label: 'Dropdown Menu Item',
        group: true,
        fields: [
          {
            key: 'account_dropdown_menu_item_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
          },
          ...createMarginFields({
            key: 'account_dropdown_menu_item',
            label: 'Menu Item',
            initial_values: {
              margin_top: 0,
              margin_right: 6,
              margin_bottom: 0,
              margin_left: 6,
            },
          }),
          ...createPaddingFields({
            key: 'account_dropdown_menu_item',
            label: 'Menu Item',
            initial_values: {
              padding_top: 8,
              padding_right: 12,
              padding_bottom: 8,
              padding_left: 12,
            },
          }),
          {
            key: 'account_dropdown_menu_item_background_color',
            label: 'Default item background color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'account_dropdown_menu_item_background_color_hover',
            label: 'Hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(46, 46, 46, 1)',
          },
          {
            key: 'account_dropdown_menu_item_border_color',
            label: 'Default item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'account_dropdown_menu_item_border_color_hover',
            label: 'Hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'account_dropdown_menu_item_border_thickness',
            label: 'Item border thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'account_dropdown_menu_item_corner_radius',
            label: 'Item corner radius',
            type: 'number_input',
            initial_value: 4,
          },
        ],
      },
      {
        label: 'Dropdown Menu Item Asset',
        group: true,
        fields: [
          createStyleField({
            key: 'account_dropdown_menu_item_asset',
            label: 'Width',
            type: 'width',
            initial_value: 16,
          }),
          createStyleField({
            key: 'account_dropdown_menu_item_asset',
            label: 'Height',
            type: 'height',
            initial_value: 16,
          }),
          ...createMarginFields({
            key: 'account_dropdown_menu_item_asset',
            label: 'Menu Item Asset',
            initial_values: {
              margin_top: 0,
              margin_right: 8,
              margin_bottom: 0,
              margin_left: 0,
            },
          }),
          {
            key: 'account_dropdown_menu_item_asset_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
          },
        ],
      },
      {
        label: 'Dropdown Menu Item Title',
        group: true,
        fields: [
          {
            key: 'account_dropdown_menu_item_title_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(158, 158, 158, 1)',
          },
          {
            key: 'account_dropdown_menu_item_title_font_color_hover',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
          },
          ...createFontFields({
            key: 'account_dropdown_menu_item_title',
            label: 'Title',
            initial_values: {
              font_family: 'Ubuntu-Medium',
              font_size: 14,
              line_height: 24,
              letter_spacing: 0,
            },
          }),
          {
            key: 'account_dropdown_menu_item_title_text_transform',
            label: 'Text transform',
            type: 'select',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
          },
          {
            key: 'account_dropdown_menu_item_title_text_decoration',
            label: 'Default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
          },
          {
            key: 'account_dropdown_menu_item_title_text_decoration_hover',
            label: 'Hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
          },
        ],
      },
      {
        label: 'Dropdown Menu Sign In',
        group: true,
        fields: [
          {
            key: 'show_sign_in_on_dropdown',
            label: 'Show Sign In on dropdown',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'account_dropdown_menu_sign_in_separate_styling_switch',
            label: 'Enable separate styling for Sign In',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/show_sign_in_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_in_default_title',
            label: 'Title',
            type: 'text_input',
            initial_value: 'Sign In',
            conditional_fields: [
              {
                key: 'styles/show_sign_in_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_in_default_asset',
            label: 'Default asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/show_sign_in_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_in_hover_asset_switch',
            label: 'Enable hover asset',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/show_sign_in_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_in_hover_asset',
            label: 'Hover asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/show_sign_in_on_dropdown',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu Sign Up',
        group: true,
        fields: [
          {
            key: 'show_sign_up_on_dropdown',
            label: 'Show Sign Up on dropdown',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'account_dropdown_menu_sign_up_separate_styling_switch',
            label: 'Enable separate styling for Sign Up',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/show_sign_up_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_up_default_title',
            label: 'Title',
            type: 'text_input',
            initial_value: 'Sign Up',
            conditional_fields: [
              {
                key: 'styles/show_sign_up_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_up_default_asset',
            label: 'Default asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/show_sign_up_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_up_hover_asset_switch',
            label: 'Enable hover asset',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/show_sign_up_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_up_hover_asset',
            label: 'Hover asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/show_sign_up_on_dropdown',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu Sign Out',
        group: true,
        fields: [
          {
            key: 'show_sign_out_on_dropdown',
            label: 'Show Sign Out on dropdown',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'account_dropdown_menu_sign_out_separate_styling_switch',
            label: 'Enable separate styling for Sign Out',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/show_sign_out_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_out_default_title',
            label: 'Title',
            type: 'text_input',
            initial_value: 'Sign Out',
            conditional_fields: [
              {
                key: 'styles/show_sign_out_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_out_default_asset',
            label: 'Default asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/show_sign_out_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_out_hover_asset_switch',
            label: 'Enable hover asset',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/show_sign_out_on_dropdown',
                condition_value: true,
              },
            ],
          },
          {
            key: 'account_dropdown_menu_sign_out_hover_asset',
            label: 'Hover asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/show_sign_out_on_dropdown',
                condition_value: true,
              },
            ],
          },
        ],
      },

      // #region Dropdown Menu Sign In Styling
      {
        label: 'Dropdown Menu Sign In Styling',
        group: true,
        fields: [
          {
            key: 'separate_log_in_styling_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          ...createMarginFields({
            key: 'separate_log_in_styling',
            label: 'Sign In',
            initial_values: {
              margin_top: 0,
              margin_right: 6,
              margin_bottom: 0,
              margin_left: 6,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          ...createPaddingFields({
            key: 'separate_log_in_styling',
            label: 'Sign In',
            initial_values: {
              padding_top: 8,
              padding_right: 12,
              padding_bottom: 8,
              padding_left: 12,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'separate_log_in_styling_background_color',
            label: 'Default item background color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_in_styling_background_color_hover',
            label: 'Hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(46, 46, 46, 0)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_in_styling_border_color',
            label: 'Default item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_in_styling_border_color_hover',
            label: 'Hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_in_styling_border_thickness',
            label: 'Item border thickness',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_in_styling_corner_radius',
            label: 'Item corner radius',
            type: 'number_input',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu Sign In Asset',
        group: true,
        fields: [
          createStyleField({
            key: 'separate_log_in_styling_asset',
            label: 'Width',
            type: 'width',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'separate_log_in_styling_asset',
            label: 'Height',
            type: 'height',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          ...createMarginFields({
            key: 'separate_log_in_styling_asset',
            label: 'Sign In Asset',
            initial_values: {
              margin_top: 0,
              margin_right: 8,
              margin_bottom: 0,
              margin_left: 0,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'separate_log_in_styling_asset_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu Sign In Title',
        group: true,
        fields: [
          {
            key: 'separate_log_in_styling_title_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(158, 158, 158, 1)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_in_styling_title_font_color_hover',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          ...createFontFields({
            key: 'separate_log_in_styling_title',
            label: 'Title',
            initial_values: {
              font_family: 'Ubuntu-Medium',
              font_size: 14,
              line_height: 24,
              letter_spacing: 0,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'separate_log_in_styling_title_text_transform',
            label: 'Text transform',
            type: 'select',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_in_styling_title_text_decoration',
            label: 'Default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_in_styling_title_text_decoration_hover',
            label: 'Hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_in_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      // #endregion

      // #region Dropdown Menu Sign Up Styling
      {
        label: 'Dropdown Menu Sign Up Styling',
        group: true,
        fields: [
          {
            key: 'separate_log_up_styling_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          ...createMarginFields({
            key: 'separate_log_up_styling',
            label: 'Sign Up',
            initial_values: {
              margin_top: 0,
              margin_right: 6,
              margin_bottom: 0,
              margin_left: 6,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          ...createPaddingFields({
            key: 'separate_log_up_styling',
            label: 'Sign Up',
            initial_values: {
              padding_top: 8,
              padding_right: 12,
              padding_bottom: 8,
              padding_left: 12,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'separate_log_up_styling_background_color',
            label: 'Default item background color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_up_styling_background_color_hover',
            label: 'Hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(46, 46, 46, 0)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_up_styling_border_color',
            label: 'Default item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_up_styling_border_color_hover',
            label: 'Hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_up_styling_border_thickness',
            label: 'Item border thickness',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_up_styling_corner_radius',
            label: 'Item corner radius',
            type: 'number_input',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu Sign Up Asset',
        group: true,
        fields: [
          createStyleField({
            key: 'separate_log_up_styling_asset',
            label: 'Width',
            type: 'width',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'separate_log_up_styling_asset',
            label: 'Height',
            type: 'height',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          ...createMarginFields({
            key: 'separate_log_up_styling_asset',
            label: 'Sign Up Asset',
            initial_values: {
              margin_top: 0,
              margin_right: 8,
              margin_bottom: 0,
              margin_left: 0,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'separate_log_up_styling_asset_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu Sign Up Title',
        group: true,
        fields: [
          {
            key: 'separate_log_up_styling_title_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(158, 158, 158, 1)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_up_styling_title_font_color_hover',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          ...createFontFields({
            key: 'separate_log_up_styling_title',
            label: 'Title',
            initial_values: {
              font_family: 'Ubuntu-Medium',
              font_size: 14,
              line_height: 24,
              letter_spacing: 0,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'separate_log_up_styling_title_text_transform',
            label: 'Text transform',
            type: 'select',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_up_styling_title_text_decoration',
            label: 'Default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_up_styling_title_text_decoration_hover',
            label: 'Hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_up_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      // #endregion

      // #region Dropdown Menu Sign Out Styling
      {
        label: 'Dropdown Menu Sign Out Styling',
        group: true,
        fields: [
          {
            key: 'separate_log_out_styling_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          ...createMarginFields({
            key: 'separate_log_out_styling',
            label: 'Sign Out',
            initial_values: {
              margin_top: 0,
              margin_right: 6,
              margin_bottom: 0,
              margin_left: 6,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          ...createPaddingFields({
            key: 'separate_log_out_styling',
            label: 'Sign Out',
            initial_values: {
              padding_top: 8,
              padding_right: 12,
              padding_bottom: 8,
              padding_left: 12,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'separate_log_out_styling_background_color',
            label: 'Default item background color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_out_styling_background_color_hover',
            label: 'Hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(46, 46, 46, 0)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_out_styling_border_color',
            label: 'Default item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_out_styling_border_color_hover',
            label: 'Hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_out_styling_border_thickness',
            label: 'Item border thickness',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_out_styling_corner_radius',
            label: 'Item corner radius',
            type: 'number_input',
            initial_value: 4,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu Sign Out Asset',
        group: true,
        fields: [
          createStyleField({
            key: 'separate_log_out_styling_asset',
            label: 'Width',
            type: 'width',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'separate_log_out_styling_asset',
            label: 'Height',
            type: 'height',
            initial_value: 16,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          ...createMarginFields({
            key: 'separate_log_out_styling_asset',
            label: 'Sign Out Asset',
            initial_values: {
              margin_top: 0,
              margin_right: 8,
              margin_bottom: 0,
              margin_left: 0,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'separate_log_out_styling_asset_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu Sign Out Title',
        group: true,
        fields: [
          {
            key: 'separate_log_out_styling_title_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(158, 158, 158, 1)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_out_styling_title_font_color_hover',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          ...createFontFields({
            key: 'separate_log_out_styling_title',
            label: 'Title',
            initial_values: {
              font_family: 'Ubuntu-Medium',
              font_size: 14,
              line_height: 24,
              letter_spacing: 0,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          }),
          {
            key: 'separate_log_out_styling_title_text_transform',
            label: 'Text transform',
            type: 'select',
            options: [
              {
                text: 'Default',
                value: 'default',
              },
              {
                text: 'Uppercase',
                value: 'uppercase',
              },
              {
                text: 'Lowercase',
                value: 'lowercase',
              },
              {
                text: 'Capitalize',
                value: 'capitalize',
              },
            ],
            initial_value: 'default',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_out_styling_title_text_decoration',
            label: 'Default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'separate_log_out_styling_title_text_decoration_hover',
            label: 'Hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_sign_out_separate_styling_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      // #endregion

      {
        label: 'Dropdown Menu Links',
        group: true,
        fields: [
          {
            key: 'extra_link_1_type',
            label: 'Link 1 Type',
            type: 'select',
            options: [
              {
                value: 'screen_target',
                text: 'Screen',
              },
              {
                value: 'external_link',
                text: 'External Link',
              },
            ],
            initial_value: 'screen_target',
          },
          {
            key: 'extra_link_1_target',
            label: 'Link 1 Target',
            type: 'screen_selector',
            conditional_fields: [
              {
                key: 'styles/extra_link_1_type',
                condition_value: 'screen_target',
              },
            ],
          },
          {
            key: 'extra_link_1_external_link',
            label: 'External Link 1 Path',
            type: 'text_input',
            conditional_fields: [
              {
                key: 'styles/extra_link_1_type',
                condition_value: 'external_link',
              },
            ],
          },
          {
            key: 'extra_link_1_label',
            label: 'Link 1 Label',
            type: 'text_input',
          },
          {
            key: 'extra_link_1_visibility',
            label: 'Link 1 Visibility',
            type: 'select',
            options: [
              { text: 'Only When Signed In', value: 'when_signed_in' },
              { text: 'Only When Signed Out', value: 'when_signed_out' },
              { text: 'Always', value: 'always' },
            ],
            initial_value: 'always',
          },
          {
            key: 'extra_link_1_asset_switch',
            label: 'Enable Link 1 Asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'extra_link_1_asset',
            label: 'Link 1 Asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/extra_link_1_asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'extra_link_1_asset_hover_switch',
            label: 'Enable Link 1 Hover Asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'extra_link_1_hover_asset',
            label: 'Link 1 Hover Asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/extra_link_1_asset_hover_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'extra_link_2_type',
            label: 'Link 2 Type',
            type: 'select',
            options: [
              {
                value: 'screen_target',
                text: 'Screen',
              },
              {
                value: 'external_link',
                text: 'External Link',
              },
            ],
            initial_value: 'screen_target',
          },
          {
            key: 'extra_link_2_target',
            label: 'Link 2 Target',
            type: 'screen_selector',
            conditional_fields: [
              {
                key: 'styles/extra_link_2_type',
                condition_value: 'screen_target',
              },
            ],
          },
          {
            key: 'extra_link_2_external_link',
            label: 'External Link 2 Path',
            type: 'text_input',
            conditional_fields: [
              {
                key: 'styles/extra_link_2_type',
                condition_value: 'external_link',
              },
            ],
          },
          {
            key: 'extra_link_2_label',
            label: 'Link 2 Label',
            type: 'text_input',
          },
          {
            key: 'extra_link_2_visibility',
            label: 'Link 2 Visibility',
            type: 'select',
            options: [
              { text: 'Only When Signed In', value: 'when_signed_in' },
              { text: 'Only When Signed Out', value: 'when_signed_out' },
              { text: 'Always', value: 'always' },
            ],
            initial_value: 'always',
          },
          {
            key: 'extra_link_2_asset_switch',
            label: 'Enable Link 2 Asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'extra_link_2_asset',
            label: 'Link 2 Asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/extra_link_2_asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'extra_link_2_asset_hover_switch',
            label: 'Enable Link 2 Hover Asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'extra_link_2_hover_asset',
            label: 'Link 2 Hover Asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/extra_link_2_asset_hover_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'extra_link_3_type',
            label: 'Link 3 Type',
            type: 'select',
            options: [
              {
                value: 'screen_target',
                text: 'Screen',
              },
              {
                value: 'external_link',
                text: 'External Link',
              },
            ],
            initial_value: 'screen_target',
          },
          {
            key: 'extra_link_3_target',
            label: 'Link 3 Target',
            type: 'screen_selector',
            conditional_fields: [
              {
                key: 'styles/extra_link_3_type',
                condition_value: 'screen_target',
              },
            ],
          },
          {
            key: 'extra_link_3_external_link',
            label: 'External Link 3 Path',
            type: 'text_input',
            conditional_fields: [
              {
                key: 'styles/extra_link_3_type',
                condition_value: 'external_link',
              },
            ],
          },
          {
            key: 'extra_link_3_label',
            label: 'Link 3 Label',
            type: 'text_input',
          },
          {
            key: 'extra_link_3_visibility',
            label: 'Link 3 Visibility',
            type: 'select',
            options: [
              { text: 'Only When Signed In', value: 'when_signed_in' },
              { text: 'Only When Signed Out', value: 'when_signed_out' },
              { text: 'Always', value: 'always' },
            ],
            initial_value: 'always',
          },
          {
            key: 'extra_link_3_asset_switch',
            label: 'Enable Link 3 Asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'extra_link_3_asset',
            label: 'Link 3 Asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/extra_link_3_asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'extra_link_3_asset_hover_switch',
            label: 'Enable Link 3 Hover Asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'extra_link_3_hover_asset',
            label: 'Link 3 Hover Asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/extra_link_3_asset_hover_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'extra_link_4_type',
            label: 'Link 4 Type',
            type: 'select',
            options: [
              {
                value: 'screen_target',
                text: 'Screen',
              },
              {
                value: 'external_link',
                text: 'External Link',
              },
            ],
            initial_value: 'screen_target',
          },
          {
            key: 'extra_link_4_target',
            label: 'Link 4 Target',
            type: 'screen_selector',
            conditional_fields: [
              {
                key: 'styles/extra_link_4_type',
                condition_value: 'screen_target',
              },
            ],
          },
          {
            key: 'extra_link_4_external_link',
            label: 'External Link 4 Path',
            type: 'text_input',
            conditional_fields: [
              {
                key: 'styles/extra_link_4_type',
                condition_value: 'external_link',
              },
            ],
          },
          {
            key: 'extra_link_4_label',
            label: 'Link 4 Label',
            type: 'text_input',
          },
          {
            key: 'extra_link_4_visibility',
            label: 'Link 4 Visibility',
            type: 'select',
            options: [
              { text: 'Only When Signed In', value: 'when_signed_in' },
              { text: 'Only When Signed Out', value: 'when_signed_out' },
              { text: 'Always', value: 'always' },
            ],
            initial_value: 'always',
          },
          {
            key: 'extra_link_4_asset_switch',
            label: 'Enable Link 4 Asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'extra_link_4_asset',
            label: 'Link 4 Asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/extra_link_4_asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'extra_link_4_asset_hover_switch',
            label: 'Enable Link 4 Hover Asset',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'extra_link_4_hover_asset',
            label: 'Link 4 Hover Asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'styles/extra_link_4_asset_hover_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Dropdown Menu Authentication Buttons Divider',
        group: true,
        fields: [
          {
            key: 'account_dropdown_menu_auth_buttons_styling_divider_switch',
            label: 'Show Authentication Buttons divider',
            type: 'switch',
            initial_value: false,
          },
          createStyleField({
            key: 'account_dropdown_menu_auth_buttons_styling_divider',
            label: 'Divider color',
            type: 'background_color',
            initial_value: 'rgba(239, 239, 239, 0.1)',
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_auth_buttons_styling_divider_switch',
                condition_value: true,
              },
            ],
          }),
          createStyleField({
            key: 'account_dropdown_menu_auth_buttons_styling',
            label: 'Divider thickness',
            type: 'divider_thickness',
            initial_value: 1,
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_auth_buttons_styling_divider_switch',
                condition_value: true,
              },
            ],
          }),
          ...createMarginFields({
            key: 'account_dropdown_menu_auth_buttons_styling_divider',
            label: 'Divider',
            initial_values: {
              margin_top: 4,
              margin_right: 0,
              margin_bottom: 4,
              margin_left: 0,
            },
            conditional_fields: [
              {
                key: 'styles/account_dropdown_menu_auth_buttons_styling_divider_switch',
                condition_value: true,
              },
            ],
          }),
        ],
      },
    ],
  },
} as const;

export default manifest;

import { twMerge } from 'tailwind-merge';

/**
 * Handles the image hover effect.
 * Respectively, the component accepts the following images:
 * 1. Default image
 * 2. Hover image
 * 3. Selected image (optional)
 * 4. Selected hover image (optional)
 * Supports each image seperately with the ability to switch between them on hover.
 * If hover image is not provided and the hover switch is true,
 * the hover effect will still be applied to the default image
 * and vice versa.
 */
export function ImageHover({
  defaultImageSrc,
  defaultImageSwitch,
  hoverImageSrc,
  hoverImageSwitch,
  isSelected,
  selectedImageSrc,
  selectedImageSwitch,
  selectedHoverImageSrc,
  selectedHoverAssetSwitch,
  classNames = '',
}: {
  defaultImageSrc: string | undefined;
  defaultImageSwitch?: boolean;
  hoverImageSrc: string | undefined;
  hoverImageSwitch?: boolean;
  isSelected?: boolean;
  selectedImageSrc?: string;
  selectedImageSwitch?: boolean;
  selectedHoverImageSrc?: string;
  selectedHoverAssetSwitch?: boolean;
  classNames?: string;
}): JSX.Element {
  const isSomeImage: boolean = [
    defaultImageSwitch && defaultImageSrc,
    hoverImageSwitch && hoverImageSrc,
    selectedImageSwitch && selectedImageSrc,
    selectedHoverAssetSwitch && selectedHoverImageSrc,
  ].some((image) => image);
  if (!isSomeImage) return <></>;

  return (
    <div className={`h-fit-content w-fit-content group shrink-0`}>
      {(typeof isSelected === 'undefined' ||
        !selectedImageSwitch ||
        isSelected === false) && (
        <>
          <img
            src={defaultImageSrc}
            className={twMerge(
              !defaultImageSwitch && 'hidden',
              defaultImageSrc && classNames,
              hoverImageSwitch && getImageHoverClasses(defaultImageSwitch)
            )}
          />
          <img
            src={hoverImageSrc}
            className={twMerge(
              !hoverImageSwitch && 'hidden',
              hoverImageSrc && classNames,
              getImageHoverClasses(hoverImageSwitch, true)
            )}
          />
        </>
      )}
      {typeof isSelected !== 'undefined' && isSelected === true && (
        <>
          <img
            src={selectedImageSrc}
            className={twMerge(
              !selectedImageSwitch && 'hidden',
              selectedImageSrc && classNames,
              selectedHoverAssetSwitch &&
                getImageHoverClasses(selectedImageSwitch)
            )}
          />
          <img
            src={selectedHoverImageSrc}
            className={twMerge(
              !selectedHoverAssetSwitch && 'hidden',
              selectedHoverImageSrc && classNames,
              getImageHoverClasses(selectedHoverAssetSwitch, true)
            )}
          />
        </>
      )}
    </div>
  );
}

function getImageHoverClasses(
  imageSwitch: boolean | undefined,
  isHover: boolean = false
): string {
  const className: string = isHover
    ? 'hidden group-hover:block'
    : 'block group-hover:hidden';
  return typeof imageSwitch === 'undefined' || imageSwitch
    ? className
    : 'hidden';
}

import { NavLink, useLoaderData } from '@remix-run/react';

import manifest from '../config/manifest';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import { getJustifyContentClassName } from '~/utils/styles';
import type { NavBarMenuListItemType } from '~/services/layout/types';
import type { loader } from '~/routes/_index';
import useCurrentLanguage from '~/hooks/use-current-language';
import { ImageHover } from '~/components/image-hover';
import { Underline } from '~/components/underline';

function getWidthClassName(size: string) {
  return size === 'fixed' ? 'w-menu-list-item' : '';
}

function NavBarMenuListItem({
  navItem,
  itemSize,
  assetSwitch,
  titleSwitch,
  assetAlignment,
  hoverAssetSwitch,
  selectedAssetSwitch,
  selectedHoverAssetSwitch,
  alignment,
}: {
  navItem: NavBarMenuListItemType;
  itemSize: string;
  assetSwitch: boolean;
  hoverAssetSwitch: boolean;
  selectedAssetSwitch: boolean;
  selectedHoverAssetSwitch: boolean;
  titleSwitch: boolean;
  assetAlignment: string;
  alignment: string;
}) {
  const { staticLinks } = useLoaderData<typeof loader>();

  const currentLanguage = useCurrentLanguage();

  const { general, localizations, assets } = getPluginConfig<typeof manifest>(
    manifest,
    navItem,
    false,
    currentLanguage
  );

  let path = '/';

  if (general?.link_type === 'external_link') {
    path = general?.external_link;
  } else {
    const staticLink = staticLinks.find(
      (link) => link.screenId === general?.target
    );
    path = staticLink?.link || '/';
  }

  const label = localizations.label || navItem.title;

  const { asset_default, asset_hover, asset_selected, asset_selected_hover } =
    assets;

  return (
    <NavLink
      key={navItem.id}
      to={path}
      className={`nav-bar-item-${navItem.id} group relative`}
      tabIndex={0}
      data-testid={`nav-bar-menu-list-item-${navItem.id}`}
    >
      {({ isActive }) => (
        <div>
          <div
            className={`${
              isActive
                ? 'border-menu-list-item-color-selected bg-menu-list-item-selected group-hover:border-menu-list-item-color-selected-hover group-hover:bg-menu-list-item-selected-hover'
                : 'border-menu-list-item-color bg-menu-list-item group-hover:border-menu-list-item-color-hover group-hover:bg-menu-list-item-hover'
            } ${getWidthClassName(
              itemSize
            )} mb-menu-list-item-b ml-menu-list-item-l mr-menu-list-item-r mt-menu-list-item-t flex items-center rounded-menu-list-item border-menu-list-item-width pb-menu-list-item-b pl-menu-list-item-l pr-menu-list-item-r pt-menu-list-item-t ${
              assetAlignment.toLowerCase() === 'left'
                ? 'flex-row'
                : 'flex-row-reverse'
            } ${getJustifyContentClassName(alignment)}`}
          >
            <ImageHover
              defaultImageSrc={asset_default}
              defaultImageSwitch={assetSwitch}
              hoverImageSrc={asset_hover}
              hoverImageSwitch={hoverAssetSwitch}
              isSelected={isActive}
              selectedImageSrc={asset_selected}
              selectedImageSwitch={selectedAssetSwitch}
              selectedHoverImageSrc={asset_selected_hover}
              selectedHoverAssetSwitch={selectedHoverAssetSwitch}
              classNames="mb-menu-list-asset-b ml-menu-list-asset-l mr-menu-list-asset-r mt-menu-list-asset-t  pb-menu-list-asset-b pl-menu-list-asset-l pr-menu-list-asset-r pt-menu-list-asset-t h-menu-list-item-asset w-menu-list-item-asset"
            />

            {titleSwitch && label.trim().length !== 0 && (
              <p
                className={`${
                  isActive
                    ? 'text-menu-list-title-color-selected menu-list-selected-text-decoration group-hover:text-menu-list-title-color-selected-hover group-hover:menu-list-selected-hover-text-decoration'
                    : 'text-menu-list-title-color menu-list-default-text-decoration group-hover:text-menu-list-title-color-hover group-hover:menu-list-hover-text-decoration'
                } font-menu_list_title text-menu-list-title-font-size leading-menu-list-title tracking-menu-list-title menu-list-title-text-transform`}
              >
                {label}
              </p>
            )}
          </div>
          <Underline
            classNames={`h-menu-list-item-underline-thickness rounded-menu-list-item-underline-corner-radius ${
              isActive
                ? 'bg-menu-list-item-selected-underline group-hover:bg-menu-list-item-selected-hover-underline'
                : 'bg-menu-list-item-underline group-hover:bg-menu-list-item-hover-underline'
            } -mt-menu-list-item-b mr-menu-list-item-r ml-menu-list-item-l`}
          />
        </div>
      )}
    </NavLink>
  );
}

export default NavBarMenuListItem;

import logger from './app/services/logger';

const tailwindConfigNames = {
  colors: ['gradient'],
  textColor: ['font_color'],
  stroke: ['stroke'],
  aspectRatio: ['aspect_ratio'],
  borderRadius: ['border_radius', 'corner_radius'],
  borderWidth: ['border_thickness'],
  borderColor: ['border_color'],
  gridTemplateColumns: ['grid_columns'],
  opacity: ['opacity'],
  backgroundColor: ['background_color'],
  backgroundImage: ['background_image'],
  gap: ['gap', 'gutter'],
  width: ['width'],
  height: ['height', 'underline_thickness', 'divider_thickness'],
  fontSize: ['font_size'],
  fontWeight: ['font_weight'],
  lineClamp: ['line_clamp', 'number_of_lines'],
  objectPosition: ['object_position', 'alignment'],
  padding: ['padding'],
  fontFamily: ['font_family'],
  margin: ['margin'],
  lineHeight: ['line_height'],
  letterSpacing: ['letter_spacing'],
  caretColor: ['caret_color'],
  boxShadow: ['box_shadow', 'image_shadow', 'text_shadow', 'shadow'],
  backdropBlur: ['background_blur'],
};

const tailwindConfigNamesToClassNames = {
  colors: 'from',
  textColor: 'text',
  stroke: 'stroke',
  aspectRatio: 'aspect',
  borderRadius: 'rounded',
  borderWidth: 'border',
  borderColor: 'border',
  gridTemplateColumns: 'grid-cols',
  opacity: 'opacity',
  backgroundColor: 'bg',
  backgroundImage: 'bg',
  gap: 'gap',
  width: 'w',
  height: 'h',
  fontSize: 'text',
  fontWeight: 'font',
  lineClamp: 'line-clamp',
  objectPosition: 'object',
  padding: 'p',
  fontFamily: 'font',
  margin: 'm',
  lineHeight: 'leading',
  letterSpacing: 'tracking',
  caretColor: 'caret',
  boxShadow: 'shadow',
  backdropBlur: 'backdrop-blur',
};

/**
 * Get arranged tailwind theme vars from plugin manifest
 * @param manifest - The plugin manifest object
 * @returns - An object that can be merged to tailwind.config.js as theme
 * @example return value:
 * {
    gap: {
      auth_buttons_horizontal_gutter: 'var(--auth_buttons_horizontal_gutter)',
    },
    width: {
      auth_button_width: 'var(--auth_button_width)',
      auth_buttons_asset_width: 'var(--auth_buttons_asset_width)',
    },
    height: {
      auth_button_underline_thickness: 'var(--auth_button_underline_thickness)',
      auth_buttons_asset_height: 'var(--auth_buttons_asset_height)',
    },
    borderRadius: {
      auth_button_underline_corner_radius: 'var(--auth_button_underline_corner_radius)',
    },
    backgroundColor: {
      auth_button_underline_background_color: 'var(--auth_button_underline_background_color)',
    },
    margin: {
      auth_button_margin_top: 'var(--auth_button_margin_top)',
      auth_button_margin_right: 'var(--auth_button_margin_right)',
      auth_button_margin_bottom: 'var(--auth_button_margin_bottom)',
      auth_button_margin_left: 'var(--auth_button_margin_left)',
    }
    more...
  }
 */
export function genTailwindVars(manifest: any) {
  try {
    const manifestKeys = getManifestKeys(manifest);

    const vars: Record<string, string> =
      transformManifestKeysToCssVars(manifestKeys);

    const arragedTailwindVars: any = {};

    Object.keys(vars).forEach((manifestKey) => {
      for (const [key, values] of Object.entries(tailwindConfigNames)) {
        if (isManifestKeyMatch(values, manifestKey)) {
          arragedTailwindVars[key] = {
            ...arragedTailwindVars[key],
            [manifestKey]: vars[manifestKey],
          };
        }
      }
    });

    return arragedTailwindVars;
  } catch (error: any) {
    logger.error(`genTailwindVars: ${error.message}`);
  }
}

/**
 * Get tailwind class names from plugin manifest
 * Should be imported in the tailwind.config.js file and be added to the safeList,
 * Then the class names can be dynamically added to the component as needed.
 * @param manifest - The plugin manifest object
 * @returns - An array of tailwind class names
 * @example return value:
 * [
  'gap-auth_buttons_horizontal_gutter',
  'w-auth_button_width',
  'h-auth_button_underline_thickness',
  'rounded-auth_button_underline_corner_radius',
  'bg-auth_button_underline_background_color',
  'group-hover:bg-auth_button_underline_background_color_hover',
  'mt-auth_button_margin_top',
  'mr-auth_button_margin_right',
  'mb-auth_button_margin_bottom',
  'ml-auth_button_margin_left',
  more...
  ]
 */
export function getTailwindClassNames(manifest: any): string[] {
  const manifestKeys = getManifestKeys(manifest);

  const vars: Record<string, string> =
    transformManifestKeysToCssVars(manifestKeys);

  const classNames: string[] = [];

  Object.keys(vars).forEach((manifestKey) => {
    for (const [key, values] of Object.entries(tailwindConfigNames)) {
      if (isManifestKeyMatch(values, manifestKey)) {
        const className: string =
          tailwindConfigNamesToClassNames[
            key as keyof typeof tailwindConfigNames
          ];

        if (manifestKey.includes('hover')) {
          classNames.push(`group-hover:${className}-${manifestKey}`);
          return;
        }

        if (key === 'padding' || key === 'margin') {
          if (manifestKey.includes('top')) {
            classNames.push(`${className}t-${manifestKey}`);
          }
          if (manifestKey.includes('right')) {
            classNames.push(`${className}r-${manifestKey}`);
          }
          if (manifestKey.includes('bottom')) {
            classNames.push(`${className}b-${manifestKey}`);
          }
          if (manifestKey.includes('left')) {
            classNames.push(`${className}l-${manifestKey}`);
          }
          return;
        }

        classNames.push(`${className}-${manifestKey}`);
      }
    }
  });

  return classNames;
}

/**
 * Transform manifest keys to css vars
 * @param manifestKeys - The manifest keys
 * @returns - An object with css vars
 * @example return value:
 * {
    auth_buttons_horizontal_gutter: 'var(--auth_buttons_horizontal_gutter)',
    auth_button_size: 'var(--auth_button_size)',
    auth_button_width: 'var(--auth_button_width)',
    auth_button_underline_thickness: 'var(--auth_button_underline_thickness)',
    more...
   }
 */
function transformManifestKeysToCssVars(
  manifestKeys: string[]
): Record<string, string> {
  const vars: Record<string, string> = {};

  manifestKeys.forEach((key) => {
    vars[key] = `var(--${key})`;
  });

  return vars;
}

function getManifestKeys(manifest: any, section = 'styles'): string[] {
  const keys: string[] = [];

  const manifestSection = manifest?.[section]?.fields;

  if (!manifestSection)
    throw new Error(`No fields found in manifest section: ${section}`);

  manifestSection.forEach((f: any) => {
    const groupFields = f?.fields;

    if (groupFields) {
      f.fields.forEach((f: any) => {
        if (f.key.includes('switch')) return;
        keys.push(f.key);
      });
      return;
    }

    if (f.key.includes('switch')) return;

    keys.push(f.key);
  });

  return keys;
}

function isManifestKeyMatch(values: string[], manifestKey: string): boolean {
  return values.some((value) =>
    new RegExp(`(^|_)${value}(_|$)`).test(manifestKey)
  );
}

/**
 * Get tailwind shadow vars from plugin manifest
 * @param manifest - The plugin manifest object
 * @param manifestNameToMatch - The manifest key name to match.
 * @returns - An object that can be merged to tailwind.config.js as theme
 * @example return value:
 * {
    tailwindVars: {
      boxShadow: {
        account_dropdown_menu: 'var(--account_dropdown_menu_shadow_y_offset) var(--account_dropdown_menu_shadow_x_offset) var(--account_dropdown_menu_shadow_blur) var(--account_dropdown_menu_shadow_spread) var(--account_dropdown_menu_shadow_color)'
      }
    },
    shadowClassName: 'shadow-account_dropdown_menu'
   }
 */
export function getTailwindShadowVars(
  manifest: string[],
  manifestNameToMatch: string
): {
  tailwindVars: {
    boxShadow: Record<string, string>;
  };
  shadowClassName: string;
} {
  const manifestKeys = getManifestKeys(manifest);

  const maybeIncorrectOrderShadowVars = manifestKeys.filter((v) =>
    v.includes(manifestNameToMatch)
  );

  const shadowVarsCorrectOrder = [
    'shadow_y_offset',
    'shadow_x_offset',
    'shadow_blur',
    'shadow_spread',
    'shadow_color',
  ];

  const sortedShadowVars = shadowVarsCorrectOrder.map((correctKey) =>
    maybeIncorrectOrderShadowVars.find((incorrectKey) =>
      incorrectKey.includes(correctKey)
    )
  );

  const shadowVars: string[] = [];

  sortedShadowVars.forEach((vshadowVar) => {
    shadowVars.push(`var(--${vshadowVar})`);
  });

  return {
    tailwindVars: {
      boxShadow: {
        [manifestNameToMatch]: shadowVars.join(' '),
      },
    },
    shadowClassName: `shadow-${manifestNameToMatch}`,
  };
}

function isPrefixMatch(className: string, manifestNameToMatch: string) {
  try {
    const classNameOmittedPrefix: string =
      className.split('-')[className.split('-').length - 1];

    const classNameOmittedPrefixSplitted: string[] =
      classNameOmittedPrefix.split('_');

    let isPrefixMatch: boolean = false;

    Array(classNameOmittedPrefixSplitted.length)
      .fill(null)
      .forEach(() => {
        classNameOmittedPrefixSplitted.pop();
        if (classNameOmittedPrefixSplitted.join('_') === manifestNameToMatch)
          isPrefixMatch = true;
      });

    return isPrefixMatch;
  } catch (error: any) {
    logger.error(`isPrefixMatch: ${error.message}`);
  }
}

/**
 * Filter out the class names that are not related to the current element.
 * @param classNames - The class names from getTailwindClassNames(manifest)
 * @param manifestNameToMatch - The manifest key name to match.
 * @param manifestReservedWords - The reserved words to filter out.
 * Manifest reserved words meaning:
 * A component can have multiple styles for different elements,
 * like button, title, menu, asset, underline, etc.
 * For example, some_manifest_key_name, will not return the class names of
 * some_manifest_key_name_button and some_manifest_key_name_title if the
 * manifestReservedWords is ['button', 'title'].
 * @returns - The filtered class names string
 */
export function filterTailwindClassNames({
  classNames,
  manifestNameToMatch,
  manifestReservedWords,
}: {
  classNames: string[];
  manifestNameToMatch: string;
  manifestReservedWords: string[];
}): string {
  const manifestNameToMatchLength = manifestNameToMatch.split('_').length;

  return classNames
    .filter((className) => {
      const isReserved: boolean = manifestReservedWords.includes(
        className.split('-')[className.split('-').length - 1].split('_')[
          manifestNameToMatchLength
        ]
      );

      if (isReserved) return false;

      return isPrefixMatch(className, manifestNameToMatch);
    })
    .join(' ');
}

import type {
  AccountButtonGeneral,
  AccountButtonStyles,
  AuthButtons,
} from './types';
import { useAuthButtonsOnClick } from './hooks';
import { Underline } from '~/components/underline';
import { ImageHover } from '~/components/image-hover';
import { getAuthButtonClassNames } from './get-class-names';
import { isAuthButtonVisible } from './getters';

export function AuthButton({
  localizations,
  isLoggedIn,
  authButtonType,
  styles,
  general,
}: {
  localizations: {
    sign_in_button_label: string;
    sign_up_button_label: string;
    sign_out_button_label: string;
  };
  isLoggedIn: boolean | null;
  authButtonType: AuthButtons;
  styles: AccountButtonStyles;
  general: AccountButtonGeneral;
}) {
  const onClick = useAuthButtonsOnClick(general);

  if (!styles.auth_buttons_switch) return <></>;

  if (!styles[`${authButtonType}_button_switch`]) return <></>;

  if (!isAuthButtonVisible({ authButtonType, isLoggedIn })) return <></>;

  const classNames: {
    auth_button: string;
    auth_buttons_title: string;
    auth_buttons_asset: string;
    auth_button_underline: string;
  } = getAuthButtonClassNames({
    authButtonType,
    styles,
  });

  return (
    <div className="group" data-testid={`auth-button-${authButtonType}`}>
      <button
        className={classNames.auth_button}
        onClick={onClick[authButtonType]}
      >
        {styles.auth_buttons_title_switch && (
          <div className={classNames.auth_buttons_title}>
            {localizations[`${authButtonType}_button_label`]}
          </div>
        )}

        <ImageHover
          defaultImageSrc={styles[`${authButtonType}_default_asset`]}
          defaultImageSwitch={styles.auth_buttons_asset_switch}
          hoverImageSrc={styles[`${authButtonType}_hover_asset`]}
          hoverImageSwitch={styles[`${authButtonType}_hover_asset_switch`]}
          classNames={classNames.auth_buttons_asset}
        />
      </button>

      <Underline classNames={classNames.auth_button_underline} />
    </div>
  );
}

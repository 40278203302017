import useCurrentLanguage from './use-current-language';
import { getPluginConfig } from '~/utils/get-plugin-configuration';

export function useLocalizationsLabels({
  manifest,
  item,
}: {
  manifest: any;
  item: any;
}): any {
  const currentLanguage = useCurrentLanguage();

  const { localizations } = getPluginConfig<typeof manifest>(
    manifest,
    item,
    false,
    currentLanguage
  );

  return localizations;
}

import { useFetcher, useLoaderData, useNavigate } from '@remix-run/react';
import { getStaticLinkPath } from './getters';
import type { loader } from '~/routes/_index';
import type { AccountButtonGeneral, StaticLinks } from './types';

export function useAuthButtonsOnClick(general: AccountButtonGeneral) {
  const { staticLinks } = useLoaderData<typeof loader>();
  const fetcher = useFetcher();
  const navigate = useNavigate();

  return {
    sign_in: (e: any) => {
      e.preventDefault();
      navigate('/login');
    },
    sign_up: () => {
      if (general.sign_up_link_type === 'external_link') {
        window.location.href = general?.sign_up_link_path || '/';
        return;
      }

      window.location.href = getStaticLinkPath(
        staticLinks as StaticLinks,
        general.sign_up_link_target
      );
    },
    sign_out: (e: any) => {
      e.preventDefault();
      fetcher.submit({}, { action: '/api/logout', method: 'post' });
    },
  };
}

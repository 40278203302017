export type LeftCenterRight = 'left' | 'center' | 'right';

/**
 * @param alignment - center, left or right.
 * @returns appropriate align-items tailwind css class
 */
export function getAlignItemsClassName({
  alignment,
  display,
  itemSize,
}: {
  alignment: string;
  display: string;
  itemSize: string;
}) {
  if (itemSize === 'fill') return 'items-stretch';

  if (display === 'vertical') {
    switch (alignment) {
      case 'center':
        return 'place-items-center';
      case 'right':
        return 'place-items-end';
      case 'left':
      default:
        return 'place-items-start';
    }
  }

  switch (alignment) {
    case 'center':
      return 'items-center';
    case 'right':
      return 'items-end';
    case 'left':
    default:
      return 'items-start';
  }
}

/**
 * @param alignment - center, left or right.
 * @returns appropriate justify-content tailwind css class
 */
export function getJustifyContentClassName(alignment: string) {
  switch (alignment) {
    case 'center':
      return 'justify-center';
    case 'right':
      return 'justify-end';
    case 'left':
    default:
      return 'justify-start';
  }
}

export type ButtonSize = 'dynamic' | 'fixed' | 'fill';

/**
 * Get button size tailwind css class
 * Note:
 * 1. 'fixed' size class is not needed as it is already applied in the button component.
 * 2. Should be applied after width class is applied so tailwind can override it.
 * @param buttonSize - dynamic, fixed or fill.
 * @returns - tailwind css class or empty string since 'fixed' size class must be already applied.
 */
export function getButtonSize(buttonSize: ButtonSize) {
  switch (buttonSize) {
    case 'dynamic':
      return 'w-auto';
    case 'fill':
      return 'w-full';
    default:
      return '';
  }
}

export function getFlexDirection(alignment: 'left' | 'right') {
  return alignment === 'left' ? 'flex-row-reverse' : '';
}

export function getTextDecoration({
  textDecoration,
  hoverTextDecoration,
}: {
  textDecoration?: string;
  hoverTextDecoration?: string;
}) {
  const isTextDecoration: boolean =
    typeof textDecoration === 'string' && textDecoration !== 'none';
  const isHoverTextDecoration: boolean =
    typeof hoverTextDecoration === 'string' && hoverTextDecoration !== 'none';

  return `${isTextDecoration ? textDecoration : ''} ${
    isHoverTextDecoration ? `group-hover:${hoverTextDecoration}` : ''
  }`;
}

export function mapAlign(align: LeftCenterRight): 'start' | 'center' | 'end' {
  return align === 'left' ? 'start' : align === 'right' ? 'end' : 'center';
}

export function getContentAlignment({
  assetAlignment,
  contentAlignment,
}: {
  assetAlignment: LeftCenterRight;
  contentAlignment: LeftCenterRight;
}) {
  if (contentAlignment === 'center') return 'justify-center';

  const isOpposite: boolean = assetAlignment === 'left';
  if (!isOpposite) return `justify-${mapAlign(contentAlignment)}`;

  return `justify-${contentAlignment === 'left' ? 'end' : 'start'}`;
}

export function Underline({
  classNames,
}: {
  classNames?: string;
}): JSX.Element {
  return (
    <div className="relative">
      <div
        className={`absolute bottom-0 left-0 right-0 top-0 ${classNames}`}
      ></div>
    </div>
  );
}

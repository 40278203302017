import { useLocalizationsLabels } from '~/hooks/use-localizations-labels';
import type { ZappNavigationNavItem } from '~/services/layout/types';
import manifest from '../config/manifest';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import { AccountDropdown } from './account-dropdown';
import { AuthButton } from './auth-buttons';
import { authButtonsNamesArray, type AuthButtons } from './types';
import { useIsLoggedIn } from '~/hooks/use-is-logged-in';
import { getAuthButtonsClassNames } from './get-class-names';

export function AccountButton2({
  navItem,
}: {
  navItem: ZappNavigationNavItem;
}) {
  const { isLoggedIn, preventRenderByIsLoggedIn } = useIsLoggedIn();

  const localizations = useLocalizationsLabels({
    manifest,
    item: navItem,
  });

  if (preventRenderByIsLoggedIn) return null;

  const { styles, general } = getPluginConfig<typeof manifest>(
    manifest,
    navItem
  );

  const classNameId: string = `responsive_nav_bar_account_button_2-${navItem.id}`;

  return (
    <section className={`${classNameId} flex`}>
      <AccountDropdown
        classNameId={classNameId}
        styles={styles}
        general={general}
        isLoggedIn={isLoggedIn}
        localizations={localizations}
      />

      <div className={getAuthButtonsClassNames()}>
        {authButtonsNamesArray.map((authButtonType, i) => (
          <AuthButton
            localizations={localizations}
            isLoggedIn={isLoggedIn}
            authButtonType={authButtonType as AuthButtons}
            styles={styles}
            general={general}
            key={`${authButtonType}-${i}`}
          />
        ))}
      </div>
    </section>
  );
}

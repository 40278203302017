import {
  authButtonsNamesArray,
  type AccountButtonGeneral,
  type AccountButtonStyles,
  type AuthButtons,
  type DropdownItem,
  type StaticLinks,
} from './types';

export function getDropdownItems({
  staticLinks,
  styles,
  general,
  isLoggedIn,
}: {
  staticLinks: StaticLinks;
  styles: any;
  general: AccountButtonGeneral;
  isLoggedIn: boolean | null;
}): DropdownItem[] {
  const dropdownItems: DropdownItem[] = [];

  [1, 2, 3, 4].forEach((i: number) => {
    const label: string = styles[`extra_link_${i}_label`];
    const linkType: string = styles[`extra_link_${i}_type`];
    const href: string =
      linkType === 'external_link'
        ? styles[`extra_link_${i}_external_link`]
        : getStaticLinkPath(staticLinks, styles[`extra_link_${i}_target`]);
    const defaultImageSrc: string = styles[`extra_link_${i}_asset`];
    const defaultImageSwitch: boolean = styles[`extra_link_${i}_asset_switch`];
    const hoverImageSrc: string = styles[`extra_link_${i}_hover_asset`];
    const hoverImageSwitch: boolean =
      styles[`extra_link_${i}_asset_hover_switch`];

    dropdownItems.push({
      label,
      href,
      defaultImageSrc,
      defaultImageSwitch,
      hoverImageSrc,
      hoverImageSwitch,
    });
  });

  let isAuthButtonsDividerAdded: boolean = false;

  authButtonsNamesArray.forEach((authButtonType: AuthButtons) => {
    if (!styles[`show_${authButtonType}_on_dropdown`]) return;

    if (!isAuthButtonVisible({ authButtonType, isLoggedIn })) return;

    const isAuthButtonsDivider =
      !!styles.account_dropdown_menu_auth_buttons_styling_divider_switch;

    const authButton = getDropdownAuthButton(
      styles,
      general,
      authButtonType as AuthButtons,
      isAuthButtonsDivider && !isAuthButtonsDividerAdded,
      staticLinks
    );

    if (isAuthButtonsDivider) isAuthButtonsDividerAdded = true;

    if (authButton) dropdownItems.push(authButton);
  });

  return dropdownItems.filter(
    (item) =>
      (item?.label || (item?.defaultImageSwitch && item?.defaultImageSrc)) &&
      item?.href
  );
}

function getDropdownAuthButton(
  styles: AccountButtonStyles,
  general: AccountButtonGeneral,
  authButtonType: AuthButtons,
  isAuthButtonsDivider: boolean,
  staticLinks: StaticLinks
): DropdownItem | undefined {
  const label: string | undefined =
    styles[`account_dropdown_menu_${authButtonType}_default_title`];

  const defaultImageSrc: string | undefined =
    styles[`account_dropdown_menu_${authButtonType}_default_asset`];
  const defaultImageSwitch: boolean =
    !!styles[`account_dropdown_menu_${authButtonType}_default_asset`];

  const hoverImageSrc: string | undefined =
    styles[`account_dropdown_menu_${authButtonType}_hover_asset`];
  const hoverImageSwitch: boolean =
    !!styles[`account_dropdown_menu_${authButtonType}_hover_asset_switch`];

  const href: string = getAuthButtonHref({
    authButtonType,
    general,
    staticLinks,
  });

  return {
    label,
    href,
    defaultImageSrc,
    defaultImageSwitch,
    hoverImageSrc,
    hoverImageSwitch,
    isAuthButtonsDivider,
    authButtonType,
  };
}

function getAuthButtonHref({
  authButtonType,
  general,
  staticLinks,
}: {
  authButtonType: AuthButtons;
  general: AccountButtonGeneral;
  staticLinks: StaticLinks;
}): string {
  if (authButtonType === 'sign_out') return '/logout';
  if (authButtonType === 'sign_in') return '/login';

  if (authButtonType === 'sign_up') {
    if (general.sign_up_link_type === 'external_link')
      return general?.sign_up_link_path || '';

    return getStaticLinkPath(staticLinks, general.sign_up_link_target);
  }

  return '';
}

export function getStaticLinkPath(
  staticLinks: StaticLinks,
  target: string | undefined
): string {
  return (
    staticLinks.find((staticLink) => staticLink.screenId === target)?.link || ''
  );
}

export function isAccountItemsVisibe({
  styles,
  isLoggedIn,
  manifestKey,
}: {
  styles: any;
  isLoggedIn: boolean | null;
  manifestKey: string;
}): boolean {
  const visibility: 'when_signed_in' | 'when_signed_out' | 'always' | 'never' =
    styles[manifestKey];

  if (visibility === 'never') return false;
  if (visibility === 'always') return true;
  if (isLoggedIn && visibility === 'when_signed_in') return true;
  if (!isLoggedIn && visibility === 'when_signed_out') return true;

  return false;
}

export function isAuthButtonVisible({
  authButtonType,
  isLoggedIn,
}: {
  authButtonType: AuthButtons;
  isLoggedIn: boolean | null;
}): boolean {
  if (authButtonType === 'sign_in' && isLoggedIn) return false;
  if (authButtonType === 'sign_up' && isLoggedIn) return false;
  if (authButtonType === 'sign_out' && !isLoggedIn) return false;
  return true;
}

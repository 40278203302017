import { useLoaderData } from '@remix-run/react';

import NavBarBreakpoint from '~/plugins/nav-bar-breakpoint/components';
import NavBarButton from '~/plugins/nav-bar-button/component';
import NavBarDrawer from '~/plugins/nav-bar-drawer/components';
import AccountButton from '~/plugins/nav-bar-account-button/components';
import NavBarMenuList from '~/plugins/nav-bar-menu-list/components';

import type { loader as indexLoader } from '~/routes/_index';
import type {
  NavBarBreakpointItem,
  ZappNavigation,
  ZappNavigationNavItem,
} from '~/services/layout/types';
import type { NewScreen } from '~/services/layout';
import { responsiveBreakpoints } from '~/services/layout/layout';
import { AccountButton2 } from '../../account-button2/components';

const navClassName = 'pointer-events-auto';

const filterItemsByBreakPoint = (
  breakpoint: number,
  items?: unknown[],
  fallback?: NavBarBreakpointItem
): NavBarBreakpointItem =>
  (items?.find(
    (item) => (item as any)?.general?.breakpoint === breakpoint.toString()
  ) || fallback) as NavBarBreakpointItem;

export default function NavBar() {
  const { navBar } = useLoaderData<typeof indexLoader>();

  if (!navBar || !navBar.nav_items) {
    return null;
  }

  const mobile = filterItemsByBreakPoint(0, navBar?.nav_items);
  const tablet = filterItemsByBreakPoint(
    responsiveBreakpoints.tablet,
    navBar?.nav_items,
    mobile
  );
  const desktop = filterItemsByBreakPoint(
    responsiveBreakpoints.desktop,
    navBar?.nav_items,
    tablet
  );
  const largeDesktop = filterItemsByBreakPoint(
    responsiveBreakpoints['large-desktop'],
    navBar?.nav_items,
    desktop
  );

  const renderChildren = (
    <>
      <NavBarBreakpoint
        navItem={mobile as NavBarBreakpointItem}
        className={'hidden mobile:max-tablet:flex'}
      />
      <NavBarBreakpoint
        navItem={tablet as NavBarBreakpointItem}
        className={'hidden tablet:max-desktop:flex'}
      />
      <NavBarBreakpoint
        navItem={desktop as NavBarBreakpointItem}
        className={'hidden desktop:max-large-desktop:flex'}
      />
      <NavBarBreakpoint
        navItem={largeDesktop as NavBarBreakpointItem}
        className={'hidden large-desktop:flex'}
      />
    </>
  );

  return <nav className={navClassName}>{renderChildren}</nav>;
}

export function NavItem({ navItem }: { navItem: ZappNavigationNavItem }) {
  switch (navItem.type) {
    case 'responsive_nav_bar_button':
      return <NavBarButton navItem={navItem} />;
    case 'responsive_nav_bar_account_button':
      return <AccountButton navItem={navItem} />;
    case 'responsive_nav_bar_account_button_2':
      return <AccountButton2 navItem={navItem} />;
    case 'responsive_nav_bar_menu_list':
      return <NavBarMenuList navItem={navItem} />;
    case 'responsive_nav_bar_drawer':
      return <NavBarDrawer navItem={navItem} />;
    default:
      return <></>;
  }
}

export function getCurrentNavBar(
  screen: NewScreen,
  navigations: Record<string, ZappNavigation>
): ZappNavigation | null {
  for (const n of screen.navigations) {
    for (const n1 in navigations) {
      if (
        n.id === navigations[n1].id &&
        navigations[n1].category === 'nav_bar' &&
        navigations[n1].navigation_type === 'responsive_nav_bar'
      ) {
        return navigations[n1];
      }
    }
  }
  return null;
}

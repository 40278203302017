import type { Fields } from '~/services/layout/types';
import manifest from '../config/manifest';

export type AccountButtonStyles = Partial<
  Fields<(typeof manifest)['styles']['fields']>
>;
export type AccountButtonGeneral = Partial<
  Fields<(typeof manifest)['general']['fields']>
>;
export type AccountButtonLocalizations = Partial<
  Fields<(typeof manifest)['localizations']['fields']>
>;

export type AuthButtons = 'sign_in' | 'sign_up' | 'sign_out';

// Authentication buttons names by priority
export const authButtonsNamesArray: AuthButtons[] = [
  'sign_in',
  'sign_up',
  'sign_out',
];

export type StaticLinks = {
  screenId: string;
  link: string;
}[];

export type DropdownItem = {
  label: string | undefined;
  href: string;
  defaultImageSrc: string | undefined;
  defaultImageSwitch: boolean;
  hoverImageSrc: string | undefined;
  hoverImageSwitch: boolean;
  isAuthButtonsDivider?: boolean;
  authButtonType?: string | undefined;
};

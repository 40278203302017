import NavBarContainer, {
  getNavBarContainers,
} from '~/plugins/nav-bar-container/components';

import manifest from '../config/manifest';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import type {
  NavBarContainerItem,
  NavBarBreakpointItem,
} from '~/services/layout/types';

function NavBarBreakpoint({
  navItem,
  className,
}: {
  navItem: NavBarBreakpointItem;
  className: string;
}) {
  if (!navItem) {
    return <></>;
  }

  const config = getPluginConfig<typeof manifest>(manifest, navItem);

  const { background_type } = config.styles;

  const { left, center, right } =
    getNavBarContainers(navItem.nav_items as NavBarContainerItem[]) || {};

  const isCenterOnly: boolean = !left && !right;

  return (
    <div
      className={`nav-bar-item-${
        navItem.id
      } fixed left-0 right-0 top-0 z-50 mb-nav-bar-breakpoint-margin-b ml-nav-bar-breakpoint-margin-l mr-nav-bar-breakpoint-margin-r mt-nav-bar-breakpoint-margin-t justify-between rounded-nav-bar-breakpoint border-nav-bar-breakpoint-width border-nav-bar-breakpoint-color pb-nav-bar-breakpoint-padding-b pl-nav-bar-breakpoint-padding-l pr-nav-bar-breakpoint-padding-r pt-nav-bar-breakpoint-padding-t ${getBackground(
        background_type
      )} ${className}`}
    >
      {(left && <NavBarContainer navItem={left} pos="left" />) ?? (
        <div className="flex-1" />
      )}
      {center && (
        <NavBarContainer
          navItem={center}
          pos="center"
          isCenterOnly={isCenterOnly}
        />
      )}
      {(right && <NavBarContainer navItem={right} pos="right" />) ?? (
        <div className="flex-1" />
      )}
    </div>
  );
}

export default NavBarBreakpoint;

function getBackground(type?: string) {
  if (!type) return '';
  if (type === 'color') return 'bg-nav-bar-breakpoint';
  if (type === 'gradient')
    return 'bg-gradient-to-b from-nav-bar-breakpoint-top-gradient to-nav-bar-breakpoint-bottom-gradient';
  return '';
}

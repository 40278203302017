import {
  filterTailwindClassNames,
  getTailwindClassNames,
} from 'gen-tailwind-vars';
import type { AccountButtonStyles, AuthButtons } from './types';
import manifest, { manifestReservedWords } from '../config/manifest';
import {
  getButtonSize,
  getContentAlignment,
  getFlexDirection,
  getTextDecoration,
  type ButtonSize,
  type LeftCenterRight,
} from '~/utils/styles';

const classNames: string[] = getTailwindClassNames(manifest);

const filterClassNames = (manifestNameToMatch: string) =>
  filterTailwindClassNames({
    classNames,
    manifestNameToMatch,
    manifestReservedWords,
  });

export function getAuthButtonsClassNames(): string {
  return `flex ${filterClassNames('auth_buttons')}`;
}

export function getAuthButtonClassNames({
  authButtonType,
  styles,
}: {
  authButtonType: AuthButtons;
  styles: AccountButtonStyles;
}) {
  return {
    auth_button: `
    flex items-center justify-center 
    ${filterClassNames(authButtonType)} 
    ${filterClassNames('auth_button')} 
    ${getButtonSize(styles.auth_button_size as ButtonSize)} 
    ${getFlexDirection(styles.auth_buttons_asset_alignment as 'left' | 'right')}
    `,
    auth_buttons_title: `
    ${filterClassNames('auth_buttons_title')} 
    ${styles.auth_buttons_title_text_transform} 
    ${getTextDecoration({
      textDecoration: styles.auth_buttons_title_text_decoration,
      hoverTextDecoration: styles.auth_buttons_title_text_decoration_hover,
    })}
    `,
    auth_buttons_asset: filterClassNames('auth_buttons_asset'),
    auth_button_underline: `
    ${filterClassNames('auth_button_underline')} 
    -mt-auth_button_margin_bottom ml-auth_button_margin_right mr-auth_button_margin_left
    `,
  };
}

export function getAccountDropdownButtonClassNames(
  styles: AccountButtonStyles
) {
  return {
    account_dropdown_button: `
    flex items-center justify-center 
    ${filterClassNames('account_dropdown_button')} 
    ${getButtonSize(styles.account_dropdown_button_size as ButtonSize)} 
    ${getFlexDirection(
      styles.account_dropdown_button_asset_alignment as 'left' | 'right'
    )} 
    `,
    account_dropdown_button_title: `
    ${filterClassNames('account_dropdown_button_title')} 
    ${getTextDecoration({
      textDecoration: styles.account_dropdown_button_title_text_decoration,
      hoverTextDecoration:
        styles.account_dropdown_button_title_text_decoration_hover,
    })} 
    ${styles.account_dropdown_button_title_text_transform}
    `,
    account_dropdown_button_chevron_asset: filterClassNames(
      'account_dropdown_button_chevron_asset'
    ),
    account_dropdown_button_asset: filterClassNames(
      'account_dropdown_button_asset'
    ),
    account_dropdown_button_underline: `
    -mt-account_dropdown_button_margin_bottom ml-account_dropdown_button_margin_right mr-account_dropdown_button_margin_left 
    ${filterClassNames('account_dropdown_button_underline')}
    `,
  };
}

export function getAccountDropdownMenuClassNames({
  styles,
}: {
  styles: AccountButtonStyles;
}) {
  const shadowClassName: string = styles.account_dropdown_menu_shadow_switch
    ? 'shadow-account_dropdown_menu'
    : '';

  return {
    account_dropdown_menu: `
    flex flex-col 
    ${filterClassNames('account_dropdown_menu')} 
    ${shadowClassName}
    `,
    account_dropdown_menu_auth_buttons_styling_divider: filterClassNames(
      'account_dropdown_menu_auth_buttons_styling_divider'
    ),
    account_dropdown_menu_item: (authButtonType: AuthButtons | undefined) => {
      const separateStylingSwitch: boolean | undefined =
        authButtonType &&
        styles[
          `account_dropdown_menu_${authButtonType}_separate_styling_switch`
        ];

      if (authButtonType) {
        authButtonType = authButtonType.replace('sign', 'log') as AuthButtons;
      }

      const menuItemAlignment = separateStylingSwitch
        ? (styles[
            `separate_${
              authButtonType as 'log_in' | 'log_up' | 'log_out'
            }_styling_alignment`
          ] as LeftCenterRight)
        : (styles.account_dropdown_menu_item_alignment as LeftCenterRight);

      const menuItemAssetAlignment = separateStylingSwitch
        ? (styles[
            `separate_${
              authButtonType as 'log_in' | 'log_up' | 'log_out'
            }_styling_asset_alignment`
          ] as 'left' | 'right')
        : (styles.account_dropdown_menu_item_asset_alignment as
            | 'left'
            | 'right');

      return `
    flex items-center 
    ${filterClassNames(
      separateStylingSwitch
        ? `separate_${authButtonType}_styling`
        : 'account_dropdown_menu_item'
    )} 
      ${getContentAlignment({
        contentAlignment: menuItemAlignment,
        assetAlignment: menuItemAssetAlignment,
      })} 
      ${getFlexDirection(menuItemAssetAlignment)}
      `;
    },
    account_dropdown_menu_item_title: (
      authButtonType: AuthButtons | undefined
    ) =>
      `
    ${filterClassNames(
      authButtonType &&
        styles[
          `account_dropdown_menu_${authButtonType}_separate_styling_switch`
        ]
        ? `separate_${authButtonType.replace('sign', 'log')}_styling_title`
        : 'account_dropdown_menu_item_title'
    )} 
      ${styles.account_dropdown_menu_item_title_text_transform} 
      ${getTextDecoration({
        textDecoration: styles.account_dropdown_menu_item_title_text_decoration,
        hoverTextDecoration:
          styles.account_dropdown_menu_item_title_text_decoration_hover,
      })}
      `,
    account_dropdown_menu_item_asset: (
      authButtonType: AuthButtons | undefined
    ) =>
      filterClassNames(
        authButtonType &&
          styles[
            `account_dropdown_menu_${authButtonType}_separate_styling_switch`
          ]
          ? `separate_${authButtonType.replace('sign', 'log')}_styling_asset`
          : 'account_dropdown_menu_item_asset'
      ),
  };
}

const propertyInputType: Record<StyleType, StyleValueType> = {
  gradient: 'color_picker_rgba',
  font_color: 'color_picker_rgba',
  stroke: 'color_picker_rgba',
  aspect_ratio: 'number_input',
  border_radius: 'number_input',
  corner_radius: 'number_input',
  border_thickness: 'number_input',
  border_color: 'color_picker_rgba',
  grid_columns: 'number_input',
  opacity: 'number_input',
  background_color: 'color_picker_rgba',
  background_image: 'text_input',
  gap: 'number_input',
  gutter: 'number_input',
  width: 'number_input',
  height: 'number_input',
  underline_thickness: 'number_input',
  divider_thickness: 'number_input',
  font_size: 'number_input',
  font_weight: 'number_input',
  line_clamp: 'number_input',
  number_of_lines: 'number_input',
  object_position: 'text_input',
  alignment: 'text_input',
  padding_top: 'number_input',
  padding_right: 'number_input',
  padding_bottom: 'number_input',
  padding_left: 'number_input',
  font_family: 'web_font_selector',
  margin_top: 'number_input',
  margin_right: 'number_input',
  margin_bottom: 'number_input',
  margin_left: 'number_input',
  line_height: 'number_input',
  letter_spacing: 'number_input',
  caret_color: 'color_picker_rgba',
  image_shadow: 'text_input',
  text_shadow: 'text_input',
  shadow: 'text_input',
  background_blur: 'number_input',
};

// #region Overload Signatures
export function createStyleField<
  P extends string,
  T extends Extract<
    StyleType,
    | 'gradient'
    | 'font_color'
    | 'stroke'
    | 'border_color'
    | 'background_color'
    | 'caret_color'
  >
>(config: {
  key: P;
  type: T;
  label: string;
  initial_value?: ColorString;
  conditional_fields?: ConditionalFields;
}): Field<P, T>;

export function createStyleField<
  P extends string,
  T extends Extract<
    StyleType,
    | 'aspect_ratio'
    | 'border_radius'
    | 'corner_radius'
    | 'border_thickness'
    | 'grid_columns'
    | 'opacity'
    | 'gap'
    | 'gutter'
    | 'width'
    | 'height'
    | 'underline_thickness'
    | 'divider_thickness'
    | 'font_size'
    | 'font_weight'
    | 'line_clamp'
    | 'number_of_lines'
    | 'padding_top'
    | 'padding_right'
    | 'padding_bottom'
    | 'padding_left'
    | 'margin_top'
    | 'margin_right'
    | 'margin_bottom'
    | 'margin_left'
    | 'line_height'
    | 'letter_spacing'
    | 'background_blur'
  >
>(config: {
  key: P;
  type: T;
  label: string;
  initial_value?: number;
  conditional_fields?: ConditionalFields;
}): Field<P, T>;

export function createStyleField<
  P extends string,
  T extends Extract<
    StyleType,
    | 'background_image'
    | 'object_position'
    | 'alignment'
    | 'image_shadow'
    | 'text_shadow'
    | 'shadow'
  >
>(config: {
  key: P;
  type: T;
  label: string;
  initial_value?: string;
  conditional_fields?: ConditionalFields;
}): Field<P, T>;

export function createStyleField<
  P extends string,
  T extends Extract<StyleType, 'font_family'>
>(config: {
  key: P;
  type: T;
  label: string;
  initial_value?: string;
  conditional_fields?: ConditionalFields;
}): Field<P, T>;
// #endregion

/**
 * The implementation function for the createStyleField to ensures correct
 * types from the overload signatures.
 * @param key - The prefix for the field
 * @param type - Type from StyleType
 * @param label - The label for the field
 * @param initial_value - (Optional) The initial value for the field
 * @param conditional_fields - (Optional) The conditional fields
 * @returns
 */
export function createStyleField<P extends string, T extends StyleType>({
  key,
  type,
  label,
  initial_value,
  conditional_fields,
}: {
  key: P;
  type: T;
  label: string;
  initial_value?: string | number;
  conditional_fields?: ConditionalFields;
}): Field<P, T> {
  return {
    key: `${key}_${type}`,
    label,
    initial_value,
    type: propertyInputType[type],
    conditional_fields,
  } as const;
}

// #region Types
type Field<P extends string, T extends StyleType> = {
  readonly key: `${P}_${T}`;
  readonly label: string;
  readonly initial_value?: string | number;
  readonly type: (typeof propertyInputType)[T];
  readonly conditional_fields?: ConditionalFields;
};

type ColorString =
  | `rgba(${number},${number},${number},${number})`
  | 'transparent';

type ConditionalFields = {
  key: string;
  condition_value: string | number | boolean;
}[];

type StyleType =
  | 'gradient'
  | 'font_color'
  | 'stroke'
  | 'aspect_ratio'
  | 'border_radius'
  | 'corner_radius'
  | 'border_thickness'
  | 'border_color'
  | 'grid_columns'
  | 'opacity'
  | 'background_color'
  | 'background_image'
  | 'gap'
  | 'gutter'
  | 'width'
  | 'height'
  | 'underline_thickness'
  | 'divider_thickness'
  | 'font_size'
  | 'font_weight'
  | 'line_clamp'
  | 'number_of_lines'
  | 'object_position'
  | 'alignment'
  | 'padding_top'
  | 'padding_right'
  | 'padding_bottom'
  | 'padding_left'
  | 'font_family'
  | 'margin_top'
  | 'margin_right'
  | 'margin_bottom'
  | 'margin_left'
  | 'line_height'
  | 'letter_spacing'
  | 'caret_color'
  | 'image_shadow'
  | 'text_shadow'
  | 'shadow'
  | 'background_blur';

type StyleValueType =
  | 'text_input'
  | 'number_input'
  | 'color_picker_rgba'
  | 'web_font_selector';
// #endregion

// #region bulk creation of margin and padding fields

type Margin = 'margin_top' | 'margin_right' | 'margin_bottom' | 'margin_left';

const margin: Margin[] = [
  'margin_top',
  'margin_right',
  'margin_bottom',
  'margin_left',
];

export function createMarginFields<P extends string>({
  key,
  label,
  initial_values,
  initial_value,
  conditional_fields,
}: {
  key: P;
  label: string;
  conditional_fields?: ConditionalFields;
} & (
  | { initial_values: Record<Margin, number>; initial_value?: never }
  | { initial_value: number; initial_values?: never }
)): Field<P, Margin>[] {
  return margin.map((type) =>
    createStyleField({
      key,
      type: type as Extract<StyleType, Margin>,
      label: `${label} ${type.replaceAll('_', ' ')}`,
      initial_value: initial_value ?? initial_values?.[type],
      conditional_fields,
    })
  );
}

type Padding =
  | 'padding_top'
  | 'padding_right'
  | 'padding_bottom'
  | 'padding_left';

const padding: Padding[] = [
  'padding_top',
  'padding_right',
  'padding_bottom',
  'padding_left',
];

export function createPaddingFields<P extends string>({
  key,
  label,
  initial_values,
  initial_value,
  conditional_fields,
}: {
  key: P;
  label: string;
  conditional_fields?: ConditionalFields;
} & (
  | { initial_values: Record<Padding, number>; initial_value?: never }
  | { initial_value: number; initial_values?: never }
)): Field<P, Padding>[] {
  return padding.map((type) =>
    createStyleField({
      key,
      type: type as Extract<StyleType, Padding>,
      label: `${label} ${type.replaceAll('_', ' ')}`,
      initial_value: initial_value ?? initial_values?.[type],
      conditional_fields,
    })
  );
}

// #endregion

// #region bulk creation of underline fields

type Undeline =
  | 'underline_thickness'
  | 'underline_corner_radius'
  | 'underline_background_color'
  | 'underline_background_color_hover';

const underline: Undeline[] = [
  'underline_thickness',
  'underline_corner_radius',
  'underline_background_color',
  'underline_background_color_hover',
];

const underlinePropertyInputType: Record<
  Undeline,
  'number_input' | 'color_picker_rgba'
> = {
  underline_thickness: 'number_input',
  underline_corner_radius: 'number_input',
  underline_background_color: 'color_picker_rgba',
  underline_background_color_hover: 'color_picker_rgba',
};

type UnderlineField<P extends string, T extends Undeline> = {
  readonly key: `${P}_${T}`;
  readonly label: string;
  readonly initial_value?: UnderlineInitialValues[T];
  readonly type: (typeof underlinePropertyInputType)[T];
  readonly conditional_fields?: ConditionalFields;
};

type UnderlineInitialValues = {
  underline_thickness: number;
  underline_corner_radius: number;
  underline_background_color: ColorString;
  underline_background_color_hover: ColorString;
};

export function createUnderlineFields<P extends string>({
  key,
  label,
  initial_values,
  conditional_fields,
}: {
  key: P;
  label: string;
  conditional_fields?: ConditionalFields;
  initial_values: UnderlineInitialValues;
}): UnderlineField<P, Undeline>[] {
  return underline.map((type) => {
    return {
      key: `${key}_${type}` as `${P}_${Undeline}`,
      type: underlinePropertyInputType[type],
      label: `${label} ${type.replaceAll('_', ' ')}`,
      initial_value: initial_values?.[type],
      conditional_fields,
    } as const;
  });
}

// #endregion

// #region bulk creation of font fields

type Font = 'font_family' | 'font_size' | 'line_height' | 'letter_spacing';

const font: Font[] = [
  'font_family',
  'font_size',
  'line_height',
  'letter_spacing',
];

const fontPropertyInputType: Record<
  Font,
  'web_font_selector' | 'number_input'
> = {
  font_family: 'web_font_selector',
  font_size: 'number_input',
  line_height: 'number_input',
  letter_spacing: 'number_input',
};

type FontField<P extends string, T extends Font> = {
  readonly key: `${P}_${T}`;
  readonly label: string;
  readonly initial_value?: FontInitialValues[T];
  readonly type: (typeof fontPropertyInputType)[T];
  readonly conditional_fields?: ConditionalFields;
};

type FontInitialValues = {
  font_family: string;
  font_size: number;
  line_height: number;
  letter_spacing: number;
};

export function createFontFields<P extends string>({
  key,
  label,
  initial_values,
  conditional_fields,
}: {
  key: P;
  label: string;
  conditional_fields?: ConditionalFields;
  initial_values: FontInitialValues;
}): FontField<P, Font>[] {
  return font.map((type) => {
    return {
      key: `${key}_${type}` as `${P}_${Font}`,
      type: fontPropertyInputType[type],
      label: `${label} ${type.replaceAll('_', ' ')}`,
      initial_value: initial_values?.[type],
      conditional_fields,
    } as const;
  });
}

// #endregion

// #region bulk creation of shadow fields

type Shadow =
  | 'shadow_x_offset'
  | 'shadow_y_offset'
  | 'shadow_blur'
  | 'shadow_spread'
  | 'shadow_color';

const shadow: Shadow[] = [
  'shadow_x_offset',
  'shadow_y_offset',
  'shadow_blur',
  'shadow_spread',
  'shadow_color',
];

const shadowPropertyInputType: Record<
  Shadow,
  'number_input' | 'color_picker_rgba'
> = {
  shadow_x_offset: 'number_input',
  shadow_y_offset: 'number_input',
  shadow_blur: 'number_input',
  shadow_spread: 'number_input',
  shadow_color: 'color_picker_rgba',
};

type ShadowInitialValues = {
  shadow_x_offset: number;
  shadow_y_offset: number;
  shadow_blur: number;
  shadow_spread: number;
  shadow_color: ColorString;
};

type ShadowField<P extends string, T extends Shadow> = {
  readonly key: `${P}_${T}`;
  readonly label: string;
  readonly initial_value?: ShadowInitialValues[T];
  readonly type: (typeof shadowPropertyInputType)[T];
  readonly conditional_fields?: ConditionalFields;
};

export function createShadowFields<P extends string>({
  key,
  label,
  initial_values,
  conditional_fields,
}: {
  key: P;
  label: string;
  conditional_fields?: ConditionalFields;
  initial_values: ShadowInitialValues;
}): ShadowField<P, Shadow>[] {
  return shadow.map((type) => {
    const label: string = type.replaceAll('_', ' ').replace(/^shadow\s*/, '');

    return {
      key: `${key}_${type}` as `${P}_${Shadow}`,
      type: shadowPropertyInputType[type],
      label: `${label.charAt(0).toUpperCase()}${label.slice(1)}`,
      initial_value: initial_values?.[type],
      conditional_fields,
    } as const;
  });
}

// #endregion
